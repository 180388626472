import { Trans, useTranslation } from 'react-i18next';

import { Carrier, getCarrierLabel } from '@agentero/models/shared';
import { Text } from '@agentero/ui';

import { carriersRoute, getCarrierLandingPath } from 'routes';

type CarriersAppointmentWidgetModalSuccessTextProps = {
	carrier: Carrier;
};

export const CarriersAppointmentWidgetModalSuccessText = ({
	carrier
}: CarriersAppointmentWidgetModalSuccessTextProps) => {
	const { t } = useTranslation('appointments');

	return (
		<>
			<Text>
				<b>
					{t('dashboard-widget.appointed-success.text1', {
						carrierValue: getCarrierLabel(carrier)
					})}
				</b>
			</Text>
			<Text>
				<Trans
					i18nKey="dashboard-widget.appointed-success.text2"
					t={t}
					components={{
						a: <a href={carriersRoute} />
					}}
				/>
			</Text>
			<Text>{t('dashboard-widget.appointed-success.text3')}</Text>
			<Text>
				<Trans
					i18nKey="dashboard-widget.appointed-success.text4"
					t={t}
					values={{
						carrierValue: getCarrierLabel(carrier)
					}}
					components={{
						a: <a href={getCarrierLandingPath(carrier)} />
					}}
				/>
			</Text>
		</>
	);
};
