import { useTranslation } from 'react-i18next';

import { Alert } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

type TierLeftDaysAlertProps = {
	size?: 'sm' | 'md';
};

export const TierLeftDaysAlert = ({ size = 'sm' }: TierLeftDaysAlertProps) => {
	const { t } = useTranslation('tier');
	const {
		data: {
			subscription: { isTrial, trialDaysLeft, isActive }
		}
	} = useAgentResource();

	return (
		<>
			{isTrial && isActive && (
				<Alert color="warning" size={size}>
					<Alert.Paragraph>
						{trialDaysLeft > 0
							? t('sellPolicyInTheNext90Days', { count: trialDaysLeft })
							: t('trialPeriodExpired')}
					</Alert.Paragraph>
				</Alert>
			)}
		</>
	);
};
