import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { InsuranceType } from '@agentero/models/opportunity';
import { Carrier } from '@agentero/models/shared';
import { Button, ButtonVariantType } from '@agentero/ui';

import { getQuotesCtaEvent } from 'packages/analytics/events/carrier-ctas';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { getUrlFromLOB } from 'routes';

type GetQuotesCtaProps = {
	carrier: Carrier;
	quotableLobsList: InsuranceType[];
	variant: ButtonVariantType;
};

const GetQuotesCta = ({ carrier, variant, quotableLobsList }: GetQuotesCtaProps) => {
	const { t } = useTranslation('appointments');

	const { trackEvent } = useAgAnalytics();

	return (
		<Link href={getUrlFromLOB(quotableLobsList)} passHref legacyBehavior>
			<Button
				as="a"
				size="lg"
				variant={variant}
				onClick={() => trackEvent(getQuotesCtaEvent(carrier))}>
				{t('cta.getQuotes')}
			</Button>
		</Link>
	);
};

export { GetQuotesCta };
