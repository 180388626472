import { ListAdvisoriesResponse } from '@agentero/grpc-clients/grpc/news-fe';
import { getDateFromGrpc, parseDate } from '@agentero/utils';

import { Carrier, getCarrierFromProto } from '../shared';

export type News = {
	id: string;
	title: string;
	label: string;
	content: string;
	carrier: Carrier;
	publicationDate: string;
	endDate?: string;
	priority: number;
};

export const parseNewsFromProto = (response: ListAdvisoriesResponse.AsObject): News[] => {
	const result = response.advisoriesList.map(advisory => {
		return {
			id: advisory.id!,
			name: '',
			title: advisory.title!,
			content: advisory.content!,
			label: advisory.action?.label || '',
			carrier: getCarrierFromProto(advisory.carrier!),
			publicationDate: parseDate({
				date: getDateFromGrpc(advisory.publicationDate),
				language: 'en-US'
			}),
			endDate: advisory.endDate
				? parseDate({
						date: getDateFromGrpc(advisory.endDate),
						language: 'en-US'
				  })
				: undefined,
			priority: advisory.priority
		};
	});

	return result;
};
