import { useTranslation } from 'react-i18next';

import { Stack } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

export const Welcome = () => {
	const { t } = useTranslation('dashboard');
	const {
		data: { firstName }
	} = useAgentResource();

	return (
		<Stack>
			<Text size="title.screen" as="h2">
				<b>{t('welcome.title', { firstName })}</b>
			</Text>
			<Text>{t('welcome.subtitle')}</Text>
		</Stack>
	);
};
