import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { isComplianceBwaValid } from '@agentero/models/agent';
import { getInsuranceTypeIcon } from '@agentero/models/opportunity';
import {
	RaterInsuranceType,
	isAnyAppointedAndAdminNotAuthenticated,
	isAnyAppointedAndAuthCarrier,
	isAnyAppointedAndUserNotAuthenticated,
	isAnyBindWithAgenteroCarrier
} from '@agentero/models/quote';
import { Box, Stack } from '@agentero/styles/jsx';
import { Avatar, Button, Card, Text } from '@agentero/ui';

import { insuranceTypeLabelKeys } from 'modules/shared/sharedLiterals';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getRaterInsuranceTypeRouteMapping, settingsAgentCarrierCredentialsRoute } from 'routes';

type StarQuotingItemProps = RaterInsuranceType;

export const StartQuotingItem = ({ insuranceType, quotableCarriers }: StarQuotingItemProps) => {
	const { t } = useTranslation(['shared', 'dashboard']);

	const {
		data: { compliance }
	} = useAgentResource();

	const isBwaCompliance = isComplianceBwaValid(compliance);

	const InsuranceTypeIcon = getInsuranceTypeIcon(insuranceType);
	const insuranceTypeLabelKey = insuranceTypeLabelKeys[insuranceType];
	const insuranceTypeLabel = t(`shared:${insuranceTypeLabelKey}`);

	const hasQuotableCarriers =
		isAnyBindWithAgenteroCarrier(quotableCarriers) ||
		isAnyAppointedAndAuthCarrier(quotableCarriers);
	const isAnyAppointedCarriedButUserNotAuth =
		isAnyAppointedAndUserNotAuthenticated(quotableCarriers);
	const isAnyAppointedCarriedButAdminNotAuth =
		isAnyAppointedAndAdminNotAuthenticated(quotableCarriers);

	const getDescription = () => {
		if (hasQuotableCarriers) {
			return t(`dashboard:startQuoting.card.description.getQuotes.${insuranceTypeLabelKey}`);
		}

		if (isAnyAppointedCarriedButUserNotAuth) {
			return t('dashboard:startQuoting.card.description.missingAgentCredentials');
		}

		if (isAnyAppointedCarriedButAdminNotAuth) {
			return (
				<span
					dangerouslySetInnerHTML={{
						__html: t('dashboard:startQuoting.card.description.missingAdminCredentials')
					}}
				/>
			);
		}

		return isBwaCompliance
			? t('dashboard:startQuoting.card.description.notAppointed')
			: t('dashboard:startQuoting.card.description.notCompliance');
	};

	const getCTA = () => {
		if (hasQuotableCarriers) {
			return (
				<Link href={getRaterInsuranceTypeRouteMapping({ insuranceType })} passHref legacyBehavior>
					<Button as="a">{t('dashboard:startQuoting.card.action.getQuotes')}</Button>
				</Link>
			);
		}

		if (isAnyAppointedCarriedButUserNotAuth) {
			return (
				<Link href={settingsAgentCarrierCredentialsRoute} passHref legacyBehavior>
					<Button as="a">{t('dashboard:startQuoting.card.action.inputCredentials')}</Button>
				</Link>
			);
		}

		return;
	};

	const CTA = getCTA();

	return (
		<Card>
			<Stack height="100%" gap="24">
				<Avatar fallback={<InsuranceTypeIcon />} size="lg" />
				<Stack gap="16" flex="1">
					<Stack>
						<Text>
							<b>{insuranceTypeLabel}</b>
						</Text>
						<Text size="body.small" color="weak">
							{getDescription()}
						</Text>
					</Stack>
					{CTA && <Box mt="auto">{CTA}</Box>}
				</Stack>
			</Stack>
		</Card>
	);
};
