import { Tier } from '@agentero/models/tier';

import { AgEvent } from '../useAgAnalytics';

export const tiersSuccessProcessEvent = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-success-process',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalCta = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-cta',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalTextUpgradingLink = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-text-upgrading-link',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalTextBookCallLink = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-text-book-call-link',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalTextComparePlanPageLink = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-text-compare-plan-page-link',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalTextPifLink = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-text-pif-link',
		properties: {
			tiers: tier
		}
	};
};

export const tiersExistingAgenciesModalCampaignLink = (tier: Tier): AgEvent => {
	return {
		eventName: 'tiers-existing-agencies-modal-campaign-link',
		properties: {
			tiers: tier
		}
	};
};
