import { AgEvent } from '../useAgAnalytics';

export const enterInAppointmentWidget = (appointmentNumber: number): AgEvent => {
	return {
		eventName: 'enter-in-appointment-widget',
		properties: {
			appointmentNumber
		}
	};
};

export const enterInCarrierPathWidget = (appointmentNumber: number): AgEvent => {
	return {
		eventName: 'enter-in-carrier-path-widget',
		properties: {
			appointmentNumber
		}
	};
};
