import { GetAgencyComplianceInformationRequest } from '@agentero/grpc-clients/grpc/agency-fe';
import { AgencyComplianceLicense, parseAgencyComplianceLicense } from '@agentero/models/compliance';
import { handleGrpcResponse } from '@agentero/service-errors';

import { agencyFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';
import { AgencyLicenseRequest } from './useAgencyLicenseQuery';

export const fetchAgencyLicense = ({
	token,
	userId,
	agencyId
}: AgencyLicenseRequest & AgenteroUserData) => {
	return new Promise<AgencyComplianceLicense>((resolve, reject) => {
		const request = new GetAgencyComplianceInformationRequest();
		request.setAgencyId(agencyId);

		agencyFrontendService.getAgencyComplianceInformation(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const agency = parseAgencyComplianceLicense(response.toObject());

				resolve(agency);
			}
		);
	});
};
