import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { css } from '@agentero/styles/css';
import { Box, Stack } from '@agentero/styles/jsx';
import { Button, Card, Text } from '@agentero/ui';

import { dashboardRoute, getPaymentPath } from 'routes';

export const TieringPromo = () => {
	const { t } = useTranslation('tier');

	return (
		<Card className={css({ backgroundColor: 'brand.90', borderColor: 'brand.70' })}>
			<Stack height="100%" direction={{ base: 'row', md: 'column' }} gap="24">
				<Box mt={{ md: 'auto' }} minWidth="6rem">
					<Image
						width={96}
						height={96}
						alt="Picture of the author"
						src="/portal-ui-content/assets/pictograms/rocket.svg"
						style={{ rotate: '45deg' }}
					/>
				</Box>
				<Stack>
					<Text size="title.subsection">
						<b>{t('promo.claim')}</b>
					</Text>
					<Text size="body.small">{t('promo.description')}</Text>
					<Box>
						<Link href={getPaymentPath(dashboardRoute)} passHref legacyBehavior>
							<Button variant="link" as="a">
								{t('promo.CTA')}
							</Button>
						</Link>
					</Box>
				</Stack>
			</Stack>
		</Card>
	);
};
