import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { Alert } from '@agentero/components';
import { ComplianceLicenseGlobalStatus } from '@agentero/models/compliance';

import { useAgentLicenseQuery } from 'packages/services/fetch/agent-fe/agent-license/useAgentLicenseQuery';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getSettingsAgentLicenseEditRoutePath } from 'routes';

export const AgentPendingAction = () => {
	const { t } = useTranslation('shared');
	const {
		data: { id }
	} = useAgentResource();
	const { data: license } = useAgentLicenseQuery(id);
	const { globalStatus } = license;

	return license?.complianceError ? (
		<Alert
			status={license.complianceError.status}
			action={
				globalStatus === ComplianceLicenseGlobalStatus.NotFound && (
					<Link href={getSettingsAgentLicenseEditRoutePath(id)}>
						<b>{t('update')}</b>
					</Link>
				)
			}>
			<span dangerouslySetInnerHTML={{ __html: license.complianceError.message }} />
		</Alert>
	) : null;
};
