import { UseQueryOptions } from '@tanstack/react-query';

import { AppointmentRequest } from '@agentero/models/appointmentRequest';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { GetAppointmentFormArgs, fetchAppointmentForm } from './fetchAppointmentForm';

export const appointmentFormKey = 'appointmentForm';

const getAppointmentFormConfig: GrpcPortalRequest<GetAppointmentFormArgs, AppointmentRequest> = {
	request: fetchAppointmentForm,
	key: ({ carrier, agencyId }) => [appointmentFormKey, agencyId, carrier]
};

export const useGetAppointmentForm = (
	data: GetAppointmentFormArgs,
	config?: UseQueryOptions<AppointmentRequest>
) => useAgQuery(getAppointmentFormConfig, data, config);
