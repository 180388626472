import { GetAgencyComplianceInformationResponse } from '@agentero/grpc-clients/grpc/agency-fe';

import { ComplianceLicense, NPN_HELP_HREF, parseComplianceLicense } from './ComplianceLicense';

export type AgencyComplianceLicense = ComplianceLicense & {
	fein: string;
};

export const parseAgencyComplianceLicense = (
	license: GetAgencyComplianceInformationResponse.AsObject
): AgencyComplianceLicense => ({
	...parseComplianceLicense(license),
	fein: license.fein
});

export const getAgencyComplianceLicenseGeneralInformation = (
	{ npn, fein, licenseState, licenseNumber }: AgencyComplianceLicense,
	statesKeys: { [key: string]: string }
) => [
	{
		label: 'compliance.license.fields.agencyNpn',
		value: npn,
		tooltip: {
			key: 'compliance.license.npnTooltip',
			href: NPN_HELP_HREF
		}
	},
	{
		label: 'compliance.license.fields.fein',
		value: fein
	},
	{
		label: 'compliance.license.fields.mainLicenseState',
		value: statesKeys[licenseState]
	},
	{
		label: 'compliance.license.fields.licenseNumber',
		value: licenseNumber
	}
];
