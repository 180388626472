import { ListCommissionsRequest } from '@agentero/grpc-clients/grpc/agency-fe';
import { CommissionItem, parseCommissions } from '@agentero/models/commission';
import { handleGrpcResponse } from '@agentero/service-errors';

import { commissionsFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';
import { FetchListComissionsArgs } from './useListCommissions';

export const fetchListCommissions = ({
	token,
	agencyId,
	userId
}: FetchListComissionsArgs & AgenteroUserData) =>
	new Promise<CommissionItem[]>((resolve, reject) => {
		const request = new ListCommissionsRequest();
		request.setAgencyId(agencyId);

		commissionsFrontendService.listCommissions(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const commissionsList = parseCommissions(response.toObject());

				resolve(commissionsList);
			}
		);
	});
