import { isAgencyAdmin, isProducerIdAvailable } from '@agentero/models/agent';
import { FeatureFlagKey } from '@agentero/models/featureflags';
import { isSubscriptionTierUpgradable } from '@agentero/models/tier';
import { Grid, GridItem, Stack } from '@agentero/styles/jsx';

import { ProducerIdAccessBanner } from 'modules/producerId/ProducerIdAccessBanner';
import { TierDeactivateAlertDashboard } from 'modules/tiersAndBilling/TierDeactivateAlertDashboard';
import { TierEliteExistingAgencyAlert } from 'modules/tiersAndBilling/TierEliteExistingAgencyAlert';
import { Content } from 'packages/layout';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useFeatureFlagsQuery } from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';

import { ProducerIdPromoWidget } from '../producerId/ProducerIdPromoWidget';
import { CarriersAppointmentWidget } from './dashboardContent/CarriersAppointmentWidget';
import { CarriersPathWidget } from './dashboardContent/CarriersPathWidget';
import { CommissionsWidget } from './dashboardContent/CommissionsWidget';
import { DashboardComplianceWidget } from './dashboardContent/DashboardComplianceWidget';
import { DashboardSlideshow } from './dashboardContent/DashboardSlideshow';
import { PendingActionsWidget } from './dashboardContent/PendingActionsWidget';
import { StartQuotingWidget } from './dashboardContent/StartQuotingWidget';
import { TieringPromo } from './dashboardContent/TieringPromo';
import { Welcome } from './dashboardContent/Welcome';

export const DashboardContent = () => {
	const {
		data: { role, subscription, appointmentsEnabled, producerIdStatus, email }
	} = useAgentResource();
	const isTieringPromoVisible =
		isSubscriptionTierUpgradable(subscription?.tier) && appointmentsEnabled;
	const isAppointedCarriersEnabled =
		useFeatureFlagsQuery()[FeatureFlagKey.AppointedCarriersInDashboard];
	const isDashboardSlideshowActive = useFeatureFlagsQuery()[FeatureFlagKey.DashboardSlideshow];
	const isFeatureFlagActive = useFeatureFlagsQuery()[FeatureFlagKey.ProducerIdEliteAccess];

	const isAgencyAdminRole = isAgencyAdmin(role);

	const isProducerIdAvailableValue = isFeatureFlagActive && isProducerIdAvailable(producerIdStatus);

	return (
		<>
			<TierDeactivateAlertDashboard />
			<Content variant="detail">
				<Content.Wrapper size="md">
					<Stack my="32" gap="56">
						<Welcome />
						<TierEliteExistingAgencyAlert />
						<ProducerIdAccessBanner producerIdStatus={producerIdStatus} email={email} />
						<PendingActionsWidget />
						{isDashboardSlideshowActive && <DashboardSlideshow />}
						<StartQuotingWidget />
						{isAppointedCarriersEnabled && isAgencyAdminRole && <CarriersAppointmentWidget />}
						<CarriersPathWidget />
						<Grid columns={{ base: 1, md: 3 }} gap="32">
							<GridItem
								colSpan={{
									base: 1,
									md: isTieringPromoVisible || isProducerIdAvailableValue ? 2 : 3
								}}>
								<DashboardComplianceWidget />
							</GridItem>
							{isTieringPromoVisible && <TieringPromo />}
							{isProducerIdAvailableValue && <ProducerIdPromoWidget />}
						</Grid>
						{isAgencyAdminRole && (
							<Grid columns={{ base: 1, md: 2 }} gap="32" hideWhenEmpty>
								<CommissionsWidget />
							</Grid>
						)}
					</Stack>
				</Content.Wrapper>
			</Content>
		</>
	);
};
