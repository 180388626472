import { News } from '@agentero/models/news';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { fetchCarrierNewsAgency } from './fetchCarrierNewsAgency';

export const carrierNewsAgencyKey = 'carrier-news-agency';

const carrierNewsAgencyConfig: GrpcPortalRequest<{}, News[]> = {
	request: fetchCarrierNewsAgency,
	key: ({ userId }) => [carrierNewsAgencyKey, userId]
};

export const useCarrierNewsAgencyQuery = () => useAgQuery(carrierNewsAgencyConfig, {});
