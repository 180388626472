import { useTranslation } from 'react-i18next';

import { Box, Stack } from '@agentero/styles/jsx';
import { Card, Text } from '@agentero/ui';

import { ComplianceWidget } from 'modules/shared/ComplianceWidget';

export const DashboardComplianceWidget = () => {
	const { t } = useTranslation('dashboard');

	return (
		<Stack gap="24" height="100%">
			<Text size="title.subsection">
				<b>{t('compliance.title')}</b>
			</Text>
			<Box height="100%" display="grid">
				<Card>
					<ComplianceWidget />
				</Card>
			</Box>
		</Stack>
	);
};
