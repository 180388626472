import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@agentero/components';
import { Tier } from '@agentero/models';
import { Campaign, isIncludedInTwoPoliciesMonths } from '@agentero/models/agent';
import { Stack, styled } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import {
	tiersExistingAgenciesModalCampaignLink,
	tiersExistingAgenciesModalCta,
	tiersExistingAgenciesModalTextBookCallLink,
	tiersExistingAgenciesModalTextComparePlanPageLink
} from 'packages/analytics/events/tiers';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { useAssignedADMCalendarQuery } from 'packages/services/fetch/agent-fe/assigned-adm-calendar/useAssignedADMCalendar';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { campaignsRoute, getPaymentPath, settingsTiersAndBillingRoute } from 'routes';

const ListItems = styled('ol', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16',
		marginLeft: '24'
	}
});

type TierDeactivateModalAlertProps = {
	onClose: () => void;
	isOpen: boolean;
};

export const TierDeactivateModalAlert = ({ onClose, isOpen }: TierDeactivateModalAlertProps) => {
	const { t } = useTranslation('tier');
	const { asPath } = useRouter();
	const {
		data: {
			subscription: { tier, campaigns }
		}
	} = useAgentResource();

	const { trackEvent } = useAgAnalytics();

	const { data } = useAssignedADMCalendarQuery();

	return isOpen ? (
		<Modal isOpen={isOpen} onDismiss={onClose}>
			<ModalContent>
				<ModalHeader>
					<Stack gap="16">
						<Text size="title.screen">{t('existingAgencies.modal.title')}</Text>
					</Stack>
				</ModalHeader>
				<ModalBody>
					<Stack gap="16">
						<Text size="title.subsection">
							<b>{t('existingAgencies.modal.body.title')}</b>
						</Text>
						<Text>{t('existingAgencies.modal.body.p1')}</Text>
						<Text size="title.subsection">
							<b>{t('existingAgencies.modal.body.title2')}</b>
						</Text>
						<Text>{t('existingAgencies.modal.body.p2')}</Text>
						<ListItems>
							<li>
								<Text>
									<Trans
										i18nKey="existingAgencies.modal.body.option1"
										t={t}
										components={{
											b: <b />
										}}
									/>
								</Text>
							</li>
							<li>
								<Text>
									<Trans
										i18nKey="existingAgencies.modal.body.option2"
										t={t}
										components={{
											b: <b />
										}}
									/>
								</Text>
							</li>
							<li>
								<Text>
									<Trans
										i18nKey="existingAgencies.modal.body.option3"
										t={t}
										components={{
											b: <b />
										}}
									/>
								</Text>
							</li>
						</ListItems>
						{isIncludedInTwoPoliciesMonths(campaigns) && (
							<>
								<Text size="title.subsection">
									<b>{t('existingAgencies.modal.body.campaignTitle')}</b>
								</Text>
								<Text>{t('existingAgencies.modal.body.campaignText')}</Text>
								<Text>
									<Trans
										i18nKey="existingAgencies.modal.body.campaignLearnMore"
										t={t}
										components={{
											a: (
												<a
													href={campaignsRoute(Campaign.TwoPolicies, asPath)}
													onClick={() => trackEvent(tiersExistingAgenciesModalCampaignLink(tier))}
												/>
											),
											b: <b />
										}}
									/>
								</Text>
								<Text>
									<Trans
										i18nKey="existingAgencies.modal.body.p5"
										t={t}
										components={{
											a: (
												<a
													href={settingsTiersAndBillingRoute}
													onClick={() =>
														trackEvent(tiersExistingAgenciesModalTextComparePlanPageLink(tier))
													}
												/>
											),
											bookcall: (
												<a
													href={data.reengagemntCalendarLink}
													onClick={() =>
														trackEvent(tiersExistingAgenciesModalTextBookCallLink(tier))
													}
												/>
											),
											b: <b />
										}}
									/>
								</Text>
							</>
						)}
					</Stack>
				</ModalBody>
				<ModalFooter>
					{tier !== Tier.Gold && (
						<Link href={getPaymentPath(asPath)} passHref legacyBehavior>
							<Button
								as="a"
								size="md"
								onClick={() => trackEvent(tiersExistingAgenciesModalCta(tier))}>
								{t('existingAgencies.modal.button')}
							</Button>
						</Link>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	) : null;
};
