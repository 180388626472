import { ListCommissionsResponse } from '@agentero/grpc-clients/grpc/agency-fe';
import { getDateFromGrpc } from '@agentero/utils';

export type CommissionItem = {
	id: string;
	agencyId: string;
	period?: Date;
	reportPath: string;
	amount: number;
	uploadedAt?: Date;
};

export const parseCommissions = (response: ListCommissionsResponse.AsObject): CommissionItem[] =>
	response.commissionsList.map(commission => ({
		id: commission.id,
		agencyId: commission.agencyId,
		period: getDateFromGrpc(commission.period),
		reportPath: commission.reportPath,
		amount: commission.amount,
		uploadedAt: getDateFromGrpc(commission.uploadedAt)
	}));
