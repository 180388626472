import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
	MarkdownView,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader
} from '@agentero/components';
import { Carrier, getCarrierLabel } from '@agentero/models/shared';
import { HStack, Stack, styled } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { GetAppointedCta } from 'modules/carriers/carriersCta/GetAppointedCta';
import { CarrierIcon } from 'packages/components/CarrierIcon';
import { useCarrierDetailsQuery } from 'packages/services/fetch/carrier-fe/carrier-details/useCarrierDetailsQuery';
import { getCarrierDetailsPath } from 'routes';

import { CarriersAppointmentWidgetModalSuccessText } from './carriersAppointmentWidgetModal/CarriersAppointmentWidgetModalSuccessText';

export const Container = styled('div', {
	base: {
		'& .markdown-body': {
			'& li': {
				'--size': '1.25em',
				position: 'relative',
				top: '0.125em',
				flexShrink: 0,
				listStyleType: 'circle',
				minWidth: 'var(--size)',
				textStyle: 'body.small'
			}
		}
	}
});

type CarriersAppointmentWidgetModalProps = {
	onClose: () => void;
	carrier?: Carrier;
};

export const CarriersAppointmentWidgetModal = ({
	onClose,
	carrier
}: CarriersAppointmentWidgetModalProps) => {
	const { t } = useTranslation(['appointments', 'shared']);

	const [isAppointedSuccess, setIsAppointedSuccess] = useState<boolean>(false);
	const { data: carrierItem } = useCarrierDetailsQuery({ carrier });

	return (
		<Modal isOpen={!!carrier} onDismiss={onClose}>
			<ModalContent>
				<ModalHeader>
					<HStack>
						<CarrierIcon carrier={carrierItem.carrier} />
						<Text size="title.body">
							<b>{getCarrierLabel(carrierItem.carrier)}</b>
						</Text>
					</HStack>
				</ModalHeader>
				<ModalBody>
					<Stack gap="32" marginInlineStart="48" marginBlockStart="8">
						<Text size="title.body">{carrierItem.brief}</Text>
						{isAppointedSuccess ? (
							<CarriersAppointmentWidgetModalSuccessText carrier={carrierItem.carrier} />
						) : (
							<MarkdownView value={carrierItem.highlights} />
						)}
					</Stack>
				</ModalBody>
				<ModalFooter>
					<HStack gap="16">
						{isAppointedSuccess ? (
							<Button
								variant="primary"
								onClick={() => {
									onClose();
								}}>
								{t('shared:ok')}
							</Button>
						) : (
							<>
								<Button
									as="a"
									variant="secondary"
									href={getCarrierDetailsPath(carrierItem.carrier)}>
									{t('shared:seeCarrierDetails')}
								</Button>
								<GetAppointedCta
									hasAppointmentForm={carrierItem.hasAppointmentForm}
									carrier={carrierItem.carrier}
									type="list"
									onAppointed={() => setIsAppointedSuccess(true)}
								/>
							</>
						)}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
