import { Suspense } from 'react';

import { Role } from '@agentero/models';
import { Stack } from '@agentero/styles/jsx';

import { TierLeftDaysAlert } from 'modules/tiersAndBilling/TierLeftDaysAlert';
import { RequestErrorBoundary } from 'packages/components/RequestErrorBoundary';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

import { AgencyEOPendingAction } from './pendingActionsWidget/AgencyEOPendingAction';
import { AgencyPendingAction } from './pendingActionsWidget/AgencyPendingAction';
import { AgentPendingAction } from './pendingActionsWidget/AgentPendingAction';

export const PendingActionsWidget = () => {
	const {
		data: { role }
	} = useAgentResource();

	return (
		<Stack gap="8" hideWhenEmpty>
			<TierLeftDaysAlert size="md" />
			<RequestErrorBoundary customError={() => null}>
				<Suspense>
					{role === Role.AgencyAdmin || (role === Role.SuperAdmin && <AgencyPendingAction />)}
				</Suspense>
			</RequestErrorBoundary>
			<RequestErrorBoundary customError={() => null}>
				<Suspense>
					<AgentPendingAction />
				</Suspense>
			</RequestErrorBoundary>
			<Suspense>
				<AgencyEOPendingAction />
			</Suspense>
		</Stack>
	);
};
