import {
	Bar,
	BarChart,
	Cell,
	LabelList,
	ReferenceLine,
	ResponsiveContainer,
	XAxis,
	YAxis
} from 'recharts';

import { styled } from '@agentero/styles/jsx';
import { token } from '@agentero/styles/tokens';
import { parsePremiumAmount } from '@agentero/utils';

import { CommissionsChartData } from 'modules/dashboard/dashboardContent/commissionsWidget/commissionsContent/getLastSixMonthsCommissions';

const CommissionsChartRoot = styled('div', {
	base: {
		textStyle: 'caption.base',
		width: '100%',
		height: '10rem',
		marginBlockEnd: '-16',
		marginInlineStart: '-16'
	}
});

type CommissionChartProps = {
	commissions: CommissionsChartData;
};

export const CommissionsChart = ({ commissions }: CommissionChartProps) => (
	<CommissionsChartRoot>
		<ResponsiveContainer>
			<BarChart data={commissions}>
				<XAxis dataKey="period" tickLine={false} minTickGap={0} />
				<YAxis
					tickFormatter={value => `${parsePremiumAmount(value)}`}
					domain={['auto', 'auto']}
					allowDataOverflow={true}
					tickCount={4}
					interval={0}
				/>
				<ReferenceLine y={0} />
				<Bar dataKey="amount" animationDuration={0} maxBarSize={24}>
					{commissions.map(({ amount }, index) => {
						const fill = amount >= 0 ? token('colors.neutral.10') : token('colors.red.40');
						return <Cell key={index} fill={fill} />;
					})}
					<LabelList dataKey="amount" content={<div />} />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	</CommissionsChartRoot>
);
