import { MarkdownView } from '@agentero/components';
import { styled } from '@agentero/styles/jsx';

const Container = styled('div', {
	base: {
		'& .markdown-body': {
			'& ul': {
				paddingLeft: '1em !important'
			},
			'& li': {
				'--size': '1.25em',
				position: 'relative',
				top: '0.125em',
				flexShrink: 0,
				listStyleType: 'circle',
				minWidth: 'var(--size)',
				textStyle: 'body.small'
			}
		}
	}
});

type CarrierDetailsHighligthsContentProps = {
	highlights: string;
};

export const CarrierDetailsHighligthsContent = ({
	highlights
}: CarrierDetailsHighligthsContentProps) => {
	return (
		<Container>
			<MarkdownView value={highlights} />
		</Container>
	);
};
