import { ListAdvisoriesRequest } from '@agentero/grpc-clients/grpc/news-fe';
import { News, parseNewsFromProto } from '@agentero/models/news';
import { handleGrpcResponse } from '@agentero/service-errors';

import { newsFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export const fetchCarrierNewsAgency = ({ token, userId }: AgenteroUserData) => {
	return new Promise<News[]>((resolve, reject) => {
		const request = new ListAdvisoriesRequest();

		newsFrontendService.listAgencyAdvisories(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const result = parseNewsFromProto(response.toObject());

				resolve(result);
			}
		);
	});
};
