import { useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@agentero/components';
import { Carrier } from '@agentero/models/shared';
import { HStack, Stack } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { CarrierDetailsHighligthsContent } from 'modules/carriers/CarrierDetails/carrierDetailsHighligths/CarrierDetailsHighligthsContent';
import { GetQuotesCta } from 'modules/carriers/carriersCta/GetQuotesCta';
import { CarrierIcon } from 'packages/components/CarrierIcon';
import { RequestErrorBoundary } from 'packages/components/RequestErrorBoundary';
import { useCarrierDetailsQuery } from 'packages/services/fetch/carrier-fe/carrier-details/useCarrierDetailsQuery';
import { getCarrierDetailsPath } from 'routes';

type CarriersPathModalProps = {
	selectedCarrier: Carrier;
	onDismiss: () => void;
};

export const CarriersPathModal = ({ selectedCarrier, onDismiss }: CarriersPathModalProps) => {
	const { t } = useTranslation(['rater', 'shared']);

	const {
		data: { brief, highlights, quotableLobsStates },
		isLoading
	} = useCarrierDetailsQuery({
		carrier: selectedCarrier
	});

	const isOpen = !isLoading;

	return isOpen ? (
		<Modal isOpen={isOpen} onDismiss={onDismiss}>
			<ModalContent>
				<ModalHeader>
					<HStack>
						<CarrierIcon carrier={selectedCarrier} />
						<Text size="title.body">
							<b>{brief}</b>
						</Text>
					</HStack>
				</ModalHeader>
				<ModalBody>
					<Stack gap="32" marginInlineStart="48" marginBlockStart="8">
						<CarrierDetailsHighligthsContent highlights={highlights} />
					</Stack>
				</ModalBody>
				<ModalFooter
					leftSide={
						<Button as="a" variant="link" href={getCarrierDetailsPath(selectedCarrier)}>
							{t('shared:learnMore')}
						</Button>
					}>
					<RequestErrorBoundary customError={() => null}>
						<GetQuotesCta
							carrier={selectedCarrier}
							variant="primary"
							quotableLobsList={quotableLobsStates.map(quotableLobStates => quotableLobStates.lob)}
						/>
					</RequestErrorBoundary>
				</ModalFooter>
			</ModalContent>
		</Modal>
	) : null;
};
