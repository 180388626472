import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { Alert, AlertStatus } from '@agentero/components';
import { OperateAs } from '@agentero/models/agency';

import { useAgencyLicenseNoSuspenseQuery } from 'packages/services/fetch/agency-fe/agency-license/useAgencyLicenseQuery';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getSettingsAgencyLicenseEditRoutePath } from 'routes';

export const AgencyPendingAction = () => {
	const { t } = useTranslation('shared');
	const {
		data: {
			agency,
			compliance: { operateAs }
		}
	} = useAgentResource();
	const { data: license } = useAgencyLicenseNoSuspenseQuery(agency.id, {
		enabled: operateAs === OperateAs.Agency
	});

	return operateAs === OperateAs.Agency && license?.complianceError ? (
		<Alert
			status={license.complianceError.status}
			action={
				license.complianceError.status === AlertStatus.Error && (
					<Link href={getSettingsAgencyLicenseEditRoutePath(agency.id)}>
						<b>{t('update')}</b>
					</Link>
				)
			}>
			<span dangerouslySetInnerHTML={{ __html: license.complianceError.message }} />
		</Alert>
	) : null;
};
