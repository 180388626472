import { useTranslation } from 'react-i18next';

import { IconAdd, IconArrowBack } from '@agentero/icons';
import { Carrier, YesNoEnum } from '@agentero/models/shared';
import { css } from '@agentero/styles/css';
import { Box, Flex, HStack, Stack } from '@agentero/styles/jsx';
import { Button, Card, CardActionArea, Text } from '@agentero/ui';

import { enterInCarrierPathWidget } from 'packages/analytics/events/dashboard';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { CarrierIcon } from 'packages/components/CarrierIcon';
import { useCarrierListQuery } from 'packages/services/fetch/carrier-fe/carrier-list/useCarrierListQuery';
import { carriersRoute } from 'routes';

import { CarriersPathModal } from './carriersPathWidget/CarriersPathModal';
import { useCarrierInQueryParam } from './shared/useCarrierQueryParam';

export const CarriersPathWidget = () => {
	const { t } = useTranslation('dashboard');
	const { selectedCarrier, selectCarrier, removeCarrierSelected } =
		useCarrierInQueryParam('carrier_path');

	const { trackEvent } = useAgAnalytics();

	const { data: carrierList, isLoading } = useCarrierListQuery({
		search: {
			page: 1,
			filters: {
				searchTerm: '',
				isBindWithAgentero: YesNoEnum.Yes
			}
		}
	});
	const isWidgetVisible = !isLoading && !!carrierList.length;

	const openModal = (carrier: Carrier) => selectCarrier(carrier);

	return isWidgetVisible ? (
		<>
			<Stack gap="24" onMouseEnter={() => trackEvent(enterInCarrierPathWidget(carrierList.length))}>
				<HStack gap="16" justifyContent="space-between">
					<Text size="title.subsection" id="carrier-path-title">
						<b>{t('carrierPath.title')}</b>
					</Text>
					<Button variant="ghost" as="a" href={carriersRoute}>
						{t('carrierAppointed.buttonSeeAll')} <IconAdd />
					</Button>
				</HStack>
				<Box overflow="auto hidden" WebkitOverflowScrolling="touch">
					<Flex gap="32">
						{carrierList.map(({ carrier, brief }) => (
							<Card key={carrier} asCover className={css({ minWidth: '14rem', width: '14rem' })}>
								<CardActionArea onClick={() => openModal(carrier)}>
									<Stack height="100%" gap="16">
										<Stack gap="24">
											<CarrierIcon carrier={carrier} />
											<Text size="body.small">
												<b>{brief}</b>
											</Text>
										</Stack>
										<Flex marginBlockStart="auto" scale={-1} alignSelf="start">
											<IconArrowBack />
										</Flex>
									</Stack>
								</CardActionArea>
							</Card>
						))}
					</Flex>
				</Box>
			</Stack>
			{selectedCarrier && (
				<CarriersPathModal selectedCarrier={selectedCarrier} onDismiss={removeCarrierSelected} />
			)}
		</>
	) : null;
};
