import { appointmentFormKey } from 'packages/services/fetch/appointments-requests/appointmentForm/useGetAppointmentForm';
import { carrierDetails } from 'packages/services/fetch/carrier-fe/carrier-details/useCarrierDetailsQuery';
import { carrierList } from 'packages/services/fetch/carrier-fe/carrier-list/useCarrierListQuery';

import { MutationConfig, UseAgMutationCallbacks, useAgMutation } from '../../useAgMutation';
import { AppointmentFormParamsArgs, updateAppointmentRequest } from './updateAppointmentRequest';

export type MutationUpdateEnableAppointmentsPageArgs = {
	agencyId: string;
	enabled: boolean;
};

const configurationQueryCarrierDetails = {
	invalidateQueries: carrier => [[carrierDetails, carrier.carrier]],
	removeQueries: () => [[carrierList], [appointmentFormKey]]
};

const configurationQueryCarrierList = {
	invalidateQueries: () => [[carrierList]],
	removeQueries: carrier => [[carrierDetails, carrier.carrier], [appointmentFormKey]]
};

const configurationQueryAppointmentForm = {
	removeQueries: carrier => [[carrierDetails, carrier.carrier], [appointmentFormKey], [carrierList]]
};

export type UpdateAppointmentRequestType = 'list' | 'details' | 'appointmentForm';

const map = {
	list: configurationQueryCarrierList,
	details: configurationQueryCarrierDetails,
	appointmentForm: configurationQueryAppointmentForm
};

const updateAppointmentRequestConfig: (
	type: UpdateAppointmentRequestType
) => MutationConfig<Omit<AppointmentFormParamsArgs, 'token' | 'userId'>, void> = type => ({
	mutation: (token, userId, data) => updateAppointmentRequest({ token, userId, ...data }),
	messages: {
		transKeys: ['appointments'],
		success: t => t('appointmentRequested'),
		error: error => error.message
	},
	...map[type]
});

export const useUpdateAppointmentRequest = (
	type: UpdateAppointmentRequestType,
	callbacks?: UseAgMutationCallbacks
) => useAgMutation(updateAppointmentRequestConfig(type), callbacks);
