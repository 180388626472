import { AppointmentRequestRequest } from '@agentero/grpc-clients/grpc/appointment-fe';
import { RaterSchema, RiskState, getRiskStateDestinations } from '@agentero/models/quote';
import { Carrier, getCarrierProtoFromCarrier } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { appointmentServiceClient } from 'packages/services/grpcClients';

type AppointmentFormParams = {
	carrier: Carrier;
	values?: RiskState;
	schema?: RaterSchema;
};

export type AppointmentFormParamsArgs = {
	token: string;
	userId: string;
} & AppointmentFormParams;

export const updateAppointmentRequest = ({
	token,
	userId,
	carrier,
	values,
	schema
}: AppointmentFormParamsArgs) =>
	new Promise<void>((resolve, reject) => {
		const request = new AppointmentRequestRequest();
		request.setCarrier(getCarrierProtoFromCarrier(carrier));

		if (values && schema) {
			const dictionary = getRiskStateDestinations(schema, values);
			Object.keys(dictionary).map(key =>
				request.getAppointmentQuestionsMap().set(key, dictionary[key])
			);
		}

		appointmentServiceClient.appointmentRequest(
			request,
			{ Authorization: `Bearer ${token}` },
			error => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}
				resolve();
			}
		);
	});
