import { Slideshow } from '@agentero/ui';

import { slideshowMouseEnter } from 'packages/analytics/events/slideshow';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';

import { useDashboardSlideshow } from './dashboardSlideshow/useDashboardSlideshow';

export const DashboardSlideshow = () => {
	const { trackEvent } = useAgAnalytics();

	const { items } = useDashboardSlideshow();

	const onMouseEnter = () => trackEvent(slideshowMouseEnter());

	return items.length > 0 ? (
		<div onMouseEnter={onMouseEnter}>
			<Slideshow items={items} delay={5000} />
		</div>
	) : null;
};
