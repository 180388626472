import { useState } from 'react';

import Link from 'next/link';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from '@agentero/styles/jsx';
import { Alert, Button } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { settingsTiersAndBillingRoute } from 'routes';

import { TierDeactivateModalAlert } from './tierDeactivateAlert/TierDeactivateModalAlert';

export const TierDeactivateAlertDashboard = () => {
	const { t } = useTranslation('tier');
	const {
		data: {
			subscription: { isActive, activeAt }
		}
	} = useAgentResource();

	const [isModalOpen, setIsModalOpen] = useState<boolean>();

	return (
		<>
			{!isActive && (
				<Box id="alertBasicExistingAgenciesDashboard" marginBlockEnd="-1">
					<Alert color="warning" size="lg">
						<Alert.Content>
							<Alert.Title>
								<b>{t('existingAgencies.alert.title')}</b>
							</Alert.Title>
							<Alert.Paragraph>
								<Trans
									i18nKey="existingAgencies.alert.description"
									t={t}
									values={{ date: activeAt }}
									components={{
										a: <a onClick={() => setIsModalOpen(true)} />,
										b: <b />
									}}
								/>
							</Alert.Paragraph>
						</Alert.Content>
						<Alert.Actions>
							<Link href={settingsTiersAndBillingRoute} passHref legacyBehavior>
								<Button variant="secondary" size="sm">
									{t('existingAgencies.alert.button')}
								</Button>
							</Link>
						</Alert.Actions>
					</Alert>
					<TierDeactivateModalAlert isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
				</Box>
			)}
		</>
	);
};
