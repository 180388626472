import { useMemo } from 'react';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { HStack, Stack } from '@agentero/styles/jsx';
import { Button, Card, Text } from '@agentero/ui';
import { formatPremium } from '@agentero/utils';

import { getLastSixMonthsCommissions } from 'modules/dashboard/dashboardContent/commissionsWidget/commissionsContent/getLastSixMonthsCommissions';
import { useListCommissionsQuery } from 'packages/services/fetch/agency-fe/list-commissions/useListCommissions';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { commissionsRoute } from 'routes';

import { CommissionsChart } from './commissionsContent/CommissionsChart';

export const CommissionsContent = () => {
	const { t } = useTranslation(['dashboard', 'shared']);
	const {
		data: {
			agency: { id: agencyId }
		}
	} = useAgentResource();
	const { data } = useListCommissionsQuery(agencyId);
	const commissions = useMemo(() => getLastSixMonthsCommissions(data, 'en-US'), [data]);
	const total = commissions.reduce((acc, { amount }) => amount + acc, 0);
	const isEmpty = commissions.every(commission => commission.amount === 0);

	return isEmpty ? null : (
		<Stack gap="24">
			<HStack justify="space-between">
				<Text size="title.subsection">
					<b>{t('commissions.title')}</b>
				</Text>
				<Link href={commissionsRoute} passHref legacyBehavior>
					<Button as="a" variant="link">
						{t('shared:seeMore')}
					</Button>
				</Link>
			</HStack>
			<Card>
				<Stack gap="32">
					<Stack gap="2">
						<Text size="body.small">{t('commissions.totalPaid')}</Text>
						<Text size="title.screen">
							<b>{formatPremium({ premium: total, hasCurrency: true })}</b>
						</Text>
					</Stack>
					<CommissionsChart commissions={commissions} />
				</Stack>
			</Card>
		</Stack>
	);
};
