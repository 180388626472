import { UseQueryOptions } from '@tanstack/react-query';

import { CarrierDetails } from '@agentero/models';

import { queryClient } from 'packages/services/QueryCache';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { GetCarrierDetailArgs, fetchCarrierDetails } from './fetchCarrierDetail';

export const carrierDetails = 'carrier-details';

const carrierDetailQueryConfiguration: GrpcPortalRequest<GetCarrierDetailArgs, CarrierDetails> = {
	request: fetchCarrierDetails,
	key: ({ userId, carrier }) => [carrierDetails, carrier, userId]
};

export const useCarrierDetailsQuery = (
	request: GetCarrierDetailArgs,
	config?: UseQueryOptions<CarrierDetails>
) => useAgQuery(carrierDetailQueryConfiguration, request, config);

export const carrierDetailsQueryInvalidate = () => {
	queryClient.invalidateQueries({ queryKey: [carrierDetails] });
};
