import { Carrier } from '@agentero/models/shared';

import { AgEvent } from '../useAgAnalytics';

export const goToCarrierPortalCtaEvent = (carrier: Carrier, placedAt: string): AgEvent => {
	return {
		eventName: 'go-to-carrier-portal-cta',
		properties: {
			carrier,
			placedAt
		}
	};
};

export const getQuotesCtaEvent = (carrier: Carrier): AgEvent => {
	return {
		eventName: 'get-quotes-cta',
		properties: {
			carrier
		}
	};
};

export const getAppointmentCtaEvent = (
	carrier: Carrier,
	placedAt: string,
	subscriptionActive: boolean
): AgEvent => {
	return {
		eventName: 'get-appointment-cta',
		properties: {
			carrier,
			placedAt,
			subscriptionActive
		}
	};
};

export const seeDetailsCtaEvent = (carrier: Carrier, label: string): AgEvent => {
	return {
		eventName: 'see-details-cta',
		properties: {
			carrier,
			label
		}
	};
};

export const activateBwaCarrierCtaEvent = (carrier: Carrier): AgEvent => {
	return {
		eventName: 'activate-bwa-cta',
		properties: {
			carrier
		}
	};
};
