import { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { EliteWhiteBadge } from '@agentero/icons';
import { ProducerIdStatus, isProducerIdAvailable } from '@agentero/models/agent';
import { FeatureFlagKey } from '@agentero/models/featureflags';
import { Alert, Button } from '@agentero/ui';

import { useFeatureFlagsQuery } from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';
import { UserSetting } from 'packages/services/user-settings/UserSetting';
import { useUserSettings } from 'packages/services/user-settings/useUserSettings';
import { getProducerIdApplication } from 'routes';

import { ProducerIdAccessModal } from './ProducerIdAccessModal';

type ProducerIdAccessBannerProps = {
	producerIdStatus: ProducerIdStatus;
	email: string;
};

export const ProducerIdAccessBanner = ({
	producerIdStatus,
	email
}: ProducerIdAccessBannerProps) => {
	const { t } = useTranslation(['tier', 'shared']);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { isSettingActive, deactivateSetting } = useUserSettings(
		UserSetting.ProducerIdAccessBanner
	);

	const isFeatureFlagActive = useFeatureFlagsQuery()[FeatureFlagKey.ProducerIdEliteAccess];

	const onClose = () => setIsModalOpen(false);

	return (
		<>
			{isProducerIdAvailable(producerIdStatus) && isSettingActive && isFeatureFlagActive && (
				<>
					<Alert color="purple" size="lg" hasIcon={false} onDismiss={() => deactivateSetting()}>
						<Alert.Pictograms>
							<EliteWhiteBadge />
						</Alert.Pictograms>
						<Alert.Content>
							<Alert.Title>
								<b>{t('producerid-banner.title')}</b>
							</Alert.Title>
							<Alert.Paragraph>
								<Trans
									t={t}
									i18nKey="producerid-banner.body"
									components={{
										a: <a href={process.env.NEXT_PUBLIC_PRODUCER_ID_LANDING} />
									}}
								/>
							</Alert.Paragraph>
						</Alert.Content>
						<Alert.Actions>
							{producerIdStatus === ProducerIdStatus.Available ? (
								<Button variant="link" onClick={() => setIsModalOpen(true)}>
									{t('producerid-banner.cta')}
								</Button>
							) : (
								<Button
									href={getProducerIdApplication(email)}
									variant="link"
									as="a"
									target="_blank"
									rel="noreferrer">
									{t('producerid-banner.goto')}
								</Button>
							)}
						</Alert.Actions>
					</Alert>
					<ProducerIdAccessModal isOpen={isModalOpen} onClose={onClose} />
				</>
			)}
		</>
	);
};
