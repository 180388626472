import { CommissionItem } from '@agentero/models/commission';

import {
	GrpcPortalRequest,
	UseAgNoSuspenseQueryOptions,
	UseAgQueryOptions,
	fetchAgQuery,
	prefetchAgQuery,
	useAgNoSuspenseQuery,
	useAgQuery
} from '../../useAgQuery';
import { fetchListCommissions } from './fetchListCommissions';

const listCommissionsKey = 'list-commissions';

export type FetchListComissionsArgs = {
	agencyId: string;
};

const listComissionsConfig: GrpcPortalRequest<FetchListComissionsArgs, CommissionItem[]> = {
	request: fetchListCommissions,
	key: ({ agencyId, userId }) => [listCommissionsKey, userId, agencyId]
};

export const useListCommissionsNoSuspenseQuery = (
	agencyId: string,
	config?: UseAgNoSuspenseQueryOptions<CommissionItem[]>
) => useAgNoSuspenseQuery(listComissionsConfig, { agencyId }, config);

export const useListCommissionsQuery = (
	agencyId: string,
	config?: UseAgQueryOptions<CommissionItem[]>
) => useAgQuery(listComissionsConfig, { agencyId }, config);

export const prefetchCommissions = async (token: string, agencyId: string, userId: string) =>
	await prefetchAgQuery(listComissionsConfig, { agencyId, token, userId });

export const fetchCommissions = async (token: string, agencyId: string, userId: string) =>
	await fetchAgQuery(listComissionsConfig, { agencyId, token, userId });
