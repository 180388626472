import { useTranslation } from 'react-i18next';

import { Header, HeaderMain, HeaderTitle, Main } from 'packages/layout';

import { DashboardContent } from './dashboard/DashboardContent';

export const Dashboard = () => {
	const { t } = useTranslation('dashboard');

	return (
		<Main>
			<Header>
				<HeaderMain>
					<HeaderTitle>{t('title')}</HeaderTitle>
				</HeaderMain>
			</Header>
			<DashboardContent />
		</Main>
	);
};
