import { Suspense } from 'react';

import { RequestErrorBoundary } from 'packages/components/RequestErrorBoundary';

import { CommissionsContent } from './commissionsWidget/CommissionsContent';

export const CommissionsWidget = () => (
	<RequestErrorBoundary customError={() => null}>
		<Suspense>
			<CommissionsContent />
		</Suspense>
	</RequestErrorBoundary>
);
