import { Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import { Stack } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

import { StartQuotingList } from './startQuotingWidget/StartQuotingList';

export const StartQuotingWidget = () => {
	const { t } = useTranslation('dashboard');

	return (
		<Stack gap="24">
			<Text size="title.subsection" as="h3">
				<b>{t('startQuoting.title')}</b>
			</Text>
			{/* TODO: add quoting list suspense fallback */}
			<Suspense>
				<StartQuotingList />
			</Suspense>
		</Stack>
	);
};
