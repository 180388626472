import { CommissionItem } from '@agentero/models/commission';
import {
	calculateMonthsDiff,
	getDateWithoutTimezoneOffset,
	parseDateToMonth
} from '@agentero/utils';

const getMonthsToSkip = (commissions: CommissionItem[], currentDate: Date) => {
	const lastCommissionPaidPeriod = getDateWithoutTimezoneOffset(commissions[0]?.period);
	const monthsDiff = calculateMonthsDiff(lastCommissionPaidPeriod, currentDate);

	if (commissions.length === 0 || monthsDiff >= 2) {
		return 2;
	}

	return monthsDiff;
};

export type CommissionsChartData = {
	period: string;
	amount: number;
}[];

export const getLastSixMonthsCommissions = (
	commissions: CommissionItem[],
	language: string,
	currentDate: Date = new Date()
): CommissionsChartData => {
	const monthToSkip = getMonthsToSkip(commissions, currentDate);

	const dates = Array.from({ length: 6 })
		.map((_, index) => {
			const date = new Date(currentDate);
			date.setDate(15);
			date.setMonth(date.getMonth() - (index + monthToSkip));

			return { year: date.getFullYear(), month: date.getMonth() };
		})
		.reverse();

	const filteredCommissions = dates.map(({ year, month }) => {
		const commission = commissions.find(({ period }) => {
			const periodWithNoTimezone = getDateWithoutTimezoneOffset(period);
			return (
				periodWithNoTimezone.getFullYear() === year && periodWithNoTimezone.getMonth() === month
			);
		});

		return commission
			? {
					period: parseDateToMonth({ date: commission.period, language }),
					amount: commission.amount
			  }
			: {
					period: parseDateToMonth({
						date: new Date(year, month, 15),
						language
					}),
					amount: 0
			  };
	});

	return filteredCommissions;
};
