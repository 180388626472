import { AgencyComplianceLicense } from '@agentero/models/compliance';

import {
	AgenteroUserData,
	GrpcPortalRequest,
	UseAgNoSuspenseQueryOptions,
	UseAgQueryOptions,
	fetchAgQuery,
	useAgNoSuspenseQuery,
	useAgQuery
} from '../../useAgQuery';
import { fetchAgencyLicense } from './fetchAgencyLicense';

export const agencyLicenseResourceKey = 'agency-license';

export type AgencyLicenseRequest = {
	agencyId: string;
};

const agencyLicenseConfig: GrpcPortalRequest<AgencyLicenseRequest, AgencyComplianceLicense> = {
	request: fetchAgencyLicense,
	key: ({ agencyId, userId }) => [agencyLicenseResourceKey, userId, agencyId]
};

export const prefetchAgencyLicense = ({
	agencyId,
	token,
	userId
}: AgencyLicenseRequest & AgenteroUserData) =>
	fetchAgQuery(agencyLicenseConfig, { agencyId, token, userId });

export const useAgencyLicenseQuery = (
	agencyId: string,
	config?: UseAgQueryOptions<AgencyComplianceLicense>
) => useAgQuery(agencyLicenseConfig, { agencyId }, config);

export const useAgencyLicenseNoSuspenseQuery = (
	agencyId: string,
	config?: UseAgNoSuspenseQueryOptions<AgencyComplianceLicense>
) => useAgNoSuspenseQuery(agencyLicenseConfig, { agencyId }, config);
