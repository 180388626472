import { GetAppointmentRequestRequest } from '@agentero/grpc-clients/grpc/appointment-fe';
import {
	AppointmentRequest,
	parseAppointmentRequestWithQuestions
} from '@agentero/models/appointmentRequest';
import { Carrier, getCarrierProtoFromCarrier } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { appointmentServiceClient } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export type GetAppointmentFormArgs = {
	carrier: Carrier;
	agencyId: string;
};

export const fetchAppointmentForm = ({
	token,
	userId,
	agencyId,
	carrier
}: GetAppointmentFormArgs & AgenteroUserData) => {
	return new Promise<AppointmentRequest>((resolve, reject) => {
		const request = new GetAppointmentRequestRequest();

		request.setAgencyId(agencyId);
		request.setCarrier(getCarrierProtoFromCarrier(carrier));

		appointmentServiceClient.getAppointmentRequest(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}
				try {
					resolve(parseAppointmentRequestWithQuestions(response.toObject()));
				} catch (e) {
					console.log(e);
					reject(e);
				}
			}
		);
	});
};
