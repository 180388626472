import { useState } from 'react';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent, ModalHeader } from '@agentero/components';
import { isAllowedToGetAppointed } from '@agentero/models';
import { isAgencyAdmin, isAppointmentsComplianceValid } from '@agentero/models/agent';
import { Carrier } from '@agentero/models/shared';
import { Button, ButtonSizeType } from '@agentero/ui';

import { getAppointmentCtaEvent } from 'packages/analytics/events/carrier-ctas';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import {
	UpdateAppointmentRequestType,
	useUpdateAppointmentRequest
} from 'packages/services/mutate/appointments/appointment-request/useUpdateAppointmentRequest';
import { appointmentRequestRoute, getPaymentPath } from 'routes';

type GetAppointedCtaProps = {
	carrier: Carrier;
	hasAppointmentForm: boolean;
	type: UpdateAppointmentRequestType;
	size?: ButtonSizeType;
	onAppointed?: () => void;
};

export const GetAppointedCta = ({
	carrier,
	hasAppointmentForm,
	type,
	size = 'md',
	onAppointed
}: GetAppointedCtaProps) => {
	const { t } = useTranslation('appointments');

	const {
		data: { role, subscription, compliance }
	} = useAgentResource();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { push, asPath } = useRouter();

	const { mutate, isPending } = useUpdateAppointmentRequest(type, {
		onSuccess: onAppointed
	});

	const { trackEvent } = useAgAnalytics();

	const isComplianceBwa = isAppointmentsComplianceValid(compliance);

	const onGetAppointed = () => {
		const isAllowedToGetAppointedValue = isAllowedToGetAppointed(subscription);

		trackEvent(getAppointmentCtaEvent(carrier, asPath, isAllowedToGetAppointedValue));

		if (!isAllowedToGetAppointedValue) {
			push(getPaymentPath(asPath));
			return;
		}

		if (isAgencyAdmin(role)) {
			if (hasAppointmentForm) {
				push(appointmentRequestRoute(carrier));
			} else {
				mutate({ carrier });
			}
		} else {
			setIsModalOpen(true);
		}
	};

	return (
		<>
			<Button
				size={size}
				variant="primary"
				onClick={onGetAppointed}
				loading={isPending}
				disabled={!isComplianceBwa || isPending}>
				{t('cta.getAppointed')}
			</Button>
			<Modal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)}>
				<ModalContent>
					<ModalHeader title={t('cta.getAppointedErrorTitle')} />
					<ModalBody>
						<div>{t('cta.getAppointedErrorText')}</div>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
