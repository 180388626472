import { useRouter } from 'next/router';

import { Carrier, isCarrierValid } from '@agentero/models/shared';

export const useCarrierInQueryParam = (queryParamName: string) => {
	const { push, pathname, query } = useRouter();

	const selectedCarrier =
		query[queryParamName] && isCarrierValid(query[queryParamName] as string)
			? (query[queryParamName] as Carrier)
			: undefined;

	const removeCarrierSelected = () => push(pathname);

	const selectCarrier = (carrier: Carrier) => {
		push({ pathname, query: { ...query, [queryParamName]: carrier } });
	};

	return { selectedCarrier, removeCarrierSelected, selectCarrier };
};
