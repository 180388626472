import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { EliteWhiteBadge, ProducerflowLogo } from '@agentero/icons';
import { ProducerIdStatus } from '@agentero/models/agent';
import { css } from '@agentero/styles/css';
import { Box, Stack } from '@agentero/styles/jsx';
import { Button, Card, Text } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getProducerIdApplication } from 'routes';

import { ProducerIdAccessModal } from './ProducerIdAccessModal';

export const ProducerIdPromoWidget = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { t } = useTranslation('tier');

	const {
		data: { producerIdStatus, email }
	} = useAgentResource();

	return (
		<>
			<Card className={css({ backgroundColor: 'purple.050', borderColor: 'purple.200' })}>
				<Stack height="100%" direction={{ base: 'row', md: 'column' }} gap="24">
					<Box minWidth="6rem">
						<EliteWhiteBadge className={css({ width: '5rem', height: '5rem' })} />
					</Box>
					<Stack>
						<ProducerflowLogo className={css({ width: '11rem' })} />
						<Text size="body.small">{t('producerid-promo.description')}</Text>
						<Stack gap="16">
							<Button
								align="start"
								variant="link"
								as="a"
								target="_blank"
								rel="noreferrer"
								href={process.env.NEXT_PUBLIC_PRODUCER_ID_LANDING}>
								{t('producer-id-modal.learn-more')}
							</Button>
							{producerIdStatus === ProducerIdStatus.Available ? (
								<Button variant="primary" onClick={() => setIsModalOpen(true)}>
									{t('producerid-promo.CTA')}
								</Button>
							) : (
								producerIdStatus === ProducerIdStatus.Onboarded && (
									<Button
										variant="primary"
										as="a"
										target="_blank"
										rel="noreferrer"
										href={getProducerIdApplication(email)}>
										{t('producerid-promo.CTA-visit')}
									</Button>
								)
							)}
						</Stack>
					</Stack>
				</Stack>
			</Card>
			<ProducerIdAccessModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</>
	);
};
