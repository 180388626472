import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { Tier } from '@agentero/models';
import { Box } from '@agentero/styles/jsx';
import { Alert, Button } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useUserSettings } from 'packages/services/user-settings/useUserSettings';
import { settingsTiersAndBillingRoute } from 'routes';

export const TierEliteExistingAgencyAlert = () => {
	const { t } = useTranslation('tier');
	const {
		data: {
			subscription: { tier, isBasedOnPif }
		}
	} = useAgentResource();

	const { isSettingActive, deactivateSetting } = useUserSettings(
		'hasExistingAgenciesEliteAlertDismissed'
	);

	return (
		<>
			{tier === Tier.Gold && isBasedOnPif && isSettingActive && (
				<Box id="alertEliteExistingAgenciesDashboard" marginBlockEnd="-1">
					<Alert color="purple" size="lg" onDismiss={deactivateSetting}>
						<Alert.Content>
							<Alert.Title>
								<b>{t('existingAgencies.alert-elite-dashboard.title')}</b>
							</Alert.Title>
							<Alert.Paragraph>{t('existingAgencies.alert-elite-dashboard.body')}</Alert.Paragraph>
						</Alert.Content>
						<Alert.Actions>
							<Link href={settingsTiersAndBillingRoute} passHref legacyBehavior>
								<Button variant="secondary" size="sm">
									{t('existingAgencies.alert-elite-dashboard.button')}
								</Button>
							</Link>
						</Alert.Actions>
					</Alert>
				</Box>
			)}
		</>
	);
};
