import { Grid } from '@agentero/styles/jsx';

import { useQuotableInsuranceTypesQuery } from 'packages/services/fetch/carrier-fe/quotable-insurance-types/useQuotableInsuranceTypes';

import { StartQuotingItem } from './startQuotingList/StartQuotingItem';

export const StartQuotingList = () => {
	const { data: quotableInsuranceTypes } = useQuotableInsuranceTypesQuery();

	return (
		<Grid columns={{ base: 1, sm: 2, md: 3 }} gap="32">
			{quotableInsuranceTypes.map(quotableInsuranceType => (
				<StartQuotingItem key={quotableInsuranceType.insuranceType} {...quotableInsuranceType} />
			))}
		</Grid>
	);
};
