import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { Alert, AlertStatus } from '@agentero/components';
import { EOStatus } from '@agentero/models/agency';
import { isAgencyAdmin } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getSettingsAgencyEORoutePath } from 'routes';

export const AgencyEOPendingAction = () => {
	const { t } = useTranslation(['shared', 'agencies']);
	const {
		data: { compliance, agency, role }
	} = useAgentResource();

	return compliance.eoStatus === EOStatus.Invalid && isAgencyAdmin(role) ? (
		<Alert
			status={AlertStatus.Error}
			action={
				<Link href={getSettingsAgencyEORoutePath(agency.id)}>
					<b>{t('shared:review')}</b>
				</Link>
			}>
			{t('agencies:agencyErrorsAndOmissions.dashboard-error')}
		</Alert>
	) : null;
};
