import { Dashboard } from 'modules/Dashboard';
import { excludeSuperAdminGuard } from 'packages/guards/superAdminGuards';
import { Page } from 'packages/layout';
import { prefetchCommissions } from 'packages/services/fetch/agency-fe/list-commissions/useListCommissions';
import { prefetchQuotableInsuranceTypesQuery } from 'packages/services/fetch/carrier-fe/quotable-insurance-types/useQuotableInsuranceTypes';
import { getIsomorphicPathname } from 'packages/utilities/getIsomorphicPathname';
import { LogLevel, addLog } from 'packages/utilities/logger';
import { AgenteroAuthPage, withAgentAuth } from 'packages/utilities/withAgentAuth';

const DashboardPage: AgenteroAuthPage = () => {
	return (
		<Page>
			<Dashboard />
		</Page>
	);
};

DashboardPage.getInitialProps = async (ctx, agent) => {
	const { token, id } = agent;
	try {
		const prefetches = [
			prefetchCommissions(token, agent.agency.id, id),
			prefetchQuotableInsuranceTypesQuery({
				userId: id,
				token
			})
		];

		await Promise.allSettled(prefetches);
	} catch (error) {
		addLog(
			{
				message: 'Error loading /dashboard',
				userId: agent.id,
				pageRoute: getIsomorphicPathname(ctx),
				error
			},
			LogLevel.Info
		);
	} finally {
		return {};
	}
};

export default withAgentAuth(DashboardPage, excludeSuperAdminGuard);
