import { AgEvent } from '../useAgAnalytics';

export const slideshowCtaClick = (id: string): AgEvent => {
	return {
		eventName: 'slideshow-cta-clicked',
		properties: {
			id
		}
	};
};

export const slideshowMouseEnter = (): AgEvent => {
	return {
		eventName: 'slideshow-mouse-enter',
		properties: {}
	};
};
